// src/components/Docs/DocsManagement.js
import React from 'react';
import UploadSection from './UploadSection';
import DocumentList from './DocumentList';
import StatusMessage from './StatusMessage';
import { useDocuments } from '../../hooks/useDocuments';

const DocsManagement = () => {
  const {
    documents,
    isUploading,
    error,
    saveStatus,
    handleUpload,
  } = useDocuments();

  return (
    <div className="space-y-6">
      <UploadSection 
        onUpload={handleUpload}
        isUploading={isUploading}
      />
      
      {error && (
        <StatusMessage
          type="error"
          message={error}
        />
      )}

      {saveStatus && (
        <StatusMessage
          type={saveStatus.type}
          message={saveStatus.message}
        />
      )}

      <DocumentList documents={documents} />
    </div>
  );
};

export default DocsManagement;