// src/components/Docs/UploadSection.js
import React from 'react';
import { Upload } from 'lucide-react';
import Card from '../Card';

const UploadSection = ({ onUpload, isUploading }) => {
  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    onUpload(files);
  };

  return (
    <Card>
      <div className="p-6">
        <h2 className="text-xl font-semibold mb-4">Upload Documents</h2>
        <div className="border-2 border-dashed border-gray-300 rounded-lg p-8">
          <div className="text-center">
            <Upload className="mx-auto h-12 w-12 text-gray-400" />
            <div className="mt-4">
              <label htmlFor="file-upload" className="cursor-pointer">
                <span className="mt-2 block text-sm font-medium text-gray-900">
                  Upload documents
                </span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="hidden"
                  multiple
                  onChange={handleFileUpload}
                  accept=".pdf,.doc,.docx,.txt"
                  disabled={isUploading}
                />
                <p className="text-xs text-gray-500">
                  PDF, DOC, DOCX, TXT up to 10MB each
                </p>
              </label>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default UploadSection;