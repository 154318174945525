// src/components/Docs/StatusMessage.js
import React from 'react';
import { AlertCircle } from 'lucide-react';

const StatusMessage = ({ status, message, type }) => {
  if (!message) return null;

  return (
    <div className={`${
      type === 'success' ? 'bg-green-50 text-green-500' : 'bg-red-50 text-red-500'
    } p-4 rounded-md flex items-center`}>
      {type === 'success' ? (
        <svg className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
        </svg>
      ) : (
        <AlertCircle className="h-5 w-5 mr-2" />
      )}
      {message}
    </div>
  );
};

export default StatusMessage;