// src/hooks/useDocuments.js

import { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { formatDistance } from 'date-fns';


export const useDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [saveStatus, setSaveStatus] = useState(null);
  const { currentUser } = useAuth();


  const functions = getFunctions();
  const firestore = getFirestore();

  // Initialize document listener
  useEffect(() => {
    const q = query(
      collection(firestore, 'docs'),
      orderBy('uploadedAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const docs = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        uploadedAt: doc.data().uploadedAt?.toDate()
      }));
      setDocuments(docs);
    }, (err) => {
      console.error('Error fetching documents:', err);
      setError('Failed to fetch documents');
    });

    return () => unsubscribe();
  }, []);

  const handleUpload = async (files) => {
    setIsUploading(true);
    setError(null);

    try {
      const uploadDocument = httpsCallable(functions, 'uploadDocument');

      for (const file of files) {
        const reader = new FileReader();
        const base64Promise = new Promise((resolve, reject) => {
          reader.onload = (e) => resolve(e.target.result.split(',')[1]);
          reader.onerror = (e) => reject(e);
          reader.readAsDataURL(file);
        });

        const base64Content = await base64Promise;

        await uploadDocument({
          name: file.name,
          type: file.type,
          size: file.size,
          base64Content,
          uploadedBy: currentUser.email
        });
      }

      setSaveStatus({
        type: 'success',
        message: 'Documents uploaded successfully!'
      });
    } catch (err) {
      console.error('Error uploading documents:', err);
      setError(err.message || 'Failed to upload documents');
      setSaveStatus({
        type: 'error',
        message: err.message || 'Failed to upload documents'
      });
    } finally {
      setIsUploading(false);
      setTimeout(() => setSaveStatus(null), 3000);
    }
  };

  const deleteDocument = async (documentId) => {
    try {
      const deleteDocumentFn = httpsCallable(functions, 'deleteDocument');
      await deleteDocumentFn({ documentId });
      
      setSaveStatus({
        type: 'success',
        message: 'Document deleted successfully!'
      });
    } catch (err) {
      console.error('Error deleting document:', err);
      setError(err.message || 'Failed to delete document');
      setSaveStatus({
        type: 'error',
        message: err.message || 'Failed to delete document'
      });
    }
  };

  const updateLabels = async (documentId, labels) => {
    try {
      const updateDocumentLabels = httpsCallable(functions, 'updateDocumentLabels');
      await updateDocumentLabels({ documentId, labels });

      setSaveStatus({
        type: 'success',
        message: 'Labels updated successfully!'
      });
    } catch (err) {
      console.error('Error updating labels:', err);
      setError(err.message || 'Failed to update labels');
      setSaveStatus({
        type: 'error',
        message: err.message || 'Failed to update labels'
      });
    }
  };

  return {
    documents,
    isUploading,
    error,
    saveStatus,
    handleUpload,
    deleteDocument,
    updateLabels,
    setSaveStatus,
  };
};